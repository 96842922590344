

























import {Vue,Component,Prop} from 'vue-property-decorator'
import { ParteModelConsulta,EditarParte } from '@/entidades/Plantacion/Partes/Consulta/ParteModelConsulta';
import {Action} from 'vuex-class';
import Alert from '@/components/Mixins/Alert';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
@Component
export default class EditarParteRechazado extends Vue
{
    @Prop({type:Object,required:true}) item!:ParteModelConsulta
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;

      headers:any[]= 
    [
        {
          text: 'Lote',
          align: 'start',
          sortable: false,
          value:'loteName'
        },
        {
          text: 'Labor',
          align: 'start',
          sortable: false,
          value:'laborName'
        },
        {
          text: 'Cuadrilla',
          align: 'start',
          sortable: false,
          value: 'cuadrillaName'
        },
        {
          text: 'Hectareas',
          align: 'start',
          sortable: false,
          value:'cantidad'
        },
         {
          text: 'precio',
          align: 'start',
          sortable: false,
          value:'precio',
        }      
    ];

    async modificar()
    {
        try
        {
            this.changeLoading(new Loading(true,"Espesre por favor....."));
            let rsp = await EditarParte(this.item);
            console.log(rsp);
            if(rsp.isSuccess == true)
            {
                this.$emit('limpiar');
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }

        
    }
}
